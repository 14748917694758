<mat-card class="mat-elevation-z4 example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image" [style.background-image]="backgroundImage"></div>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle *ngIf="subTitle">{{ subTitle }}</mat-card-subtitle>
  </mat-card-header>
  <!-- <img mat-card-image [src]="img" alt="Photo of a Shiba Inu"> -->
  <mat-card-content>
    <p style="padding-top: 2em;">
      {{ description }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <div style="text-align: right;">
      <a mat-stroked-button color="primary" [href]="link" target="_blank">GO !</a>
    </div>
  </mat-card-actions>
</mat-card>