export const data = [
    {
        title: 'Jeedom',
        subTitle: 'Raspberry Pi',
        link: 'https://jeedom.perso.rigot.dev',
        img: 'assets/logo-jeedom-grand-nom-couleur-460x320.png',
        description: 'Serveur domotique',
    },
    {
        title: "Drive",
        subTitle: "NAS",
        link: "https://perso.rigot.dev/drive",
        img: "assets/Synology_Drive.png",
        description: "Gestion et partage de fichiers",
    },
    {
        title: "Photos",
        subTitle: 'NAS',
        link: "https://perso.rigot.dev/photo",
        img: "assets/syno_photo.png",
        description: "Albums photos",
    },
    {
        title: "Fichiers",
        subTitle: 'NAS',
        link: "https://perso.rigot.dev/file",
        img: "assets/syno_file.png",
        description: "Consulter les fichiers sur le NAS",
    },
    {
        title: "Administration NAS",
        subTitle: 'NAS',
        link: "https://perso.rigot.dev:5001",
        img: "assets/syno.png",
        description: "Interface d'administration du  NAS",
    }, {
        title: "Administration NAS Maman",
        subTitle: 'NAS Maman',
        link: "https://fanfan.rigot.dev:5014",
        img: "assets/syno.png",
        description: "Interface d'administration du NAS (maman)",
    }, {
        title: "Gestion des congés",
        subTitle: 'Application Firebase',
        link: "https://conges.rigot.dev",
        description: "Application de suivi des congés",
    }, {
        title: "Scores",
        subTitle: 'Application Vercel',
        link: "https://score.rigot.dev",
        description: "Application de saisie des scores",
    }, {
        title: "Scores triomino",
        subTitle: 'Application Heroku',
        link: "http://score-triomino.herokuapp.com/",
        description: "Application de saisie des scores adapté au jeu Triomino",
    }
]
