import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-link',
  templateUrl: './card-link.component.html',
  styleUrls: ['./card-link.component.scss']
})
export class CardLinkComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() img!: string;
  @Input() link: string;
  @Input() description: string;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundImage(): string | null {
    return this.img ? `url(${this.img})` : null;
  }
}
